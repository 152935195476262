import React, { useEffect } from "react";
import "./Services.css";
import about1 from "../../Images/about-1.jpg";
import about2 from "../../Images/about-2.jpg";
import ScrollButton from "../../Components/ScrollToTop/ScrollButton";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { FaMobileAlt } from "react-icons/fa";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-section">
      <div className="about">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="title">
            <p>Services</p>
          </div>
        </AnimationOnScroll>
      </div>
      {/* <div className='container'>
        <div className='row mt-4 mb-4 align-items-center'>
          <div className='col-md-5 mb-5'>
            <div className='about-info'>
              <AnimationOnScroll animateIn='animate__fadeInUp' delay={100}>
                <h2>Who We Are</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn='animate__fadeInUp' delay={150}>
                <p>
                  In this Digital World and also being Design Development
                  Company for Web and Mobile, we have provided our clients with
                  best Web and Mobile app's Design and Solutions. We at Pearl's
                  IT Solutions, have a motto of Customer satisfaction as our
                  Priority.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-6 mb-5'>
            <AnimationOnScroll animateIn='animate__zoomIn'>
              <div className='image'>
                <img src={about1} alt='about' className='img-fluid' />
              </div>
            </AnimationOnScroll>
          </div>
          <div className='col-md-6'>
            <AnimationOnScroll animateIn='animate__zoomIn'>
              <div className='image'>
                <img src={about2} alt='about' className='img-fluid' />
              </div>
            </AnimationOnScroll>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-5'>
            <div className='about-info'>
              <AnimationOnScroll animateIn='animate__fadeInUp' delay={100}>
                <h2>Our Vision and Mission</h2>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn='animate__fadeInUp' delay={150}>
                <p>
                  Our Vision is to provide Best-In-Class Development to our
                  Clients and met their expectations with innovative and
                  excellence team members. Our Mission is to create products
                  that are based on innovation that will create different effect
                  in the Digital World.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Digital Transformation</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <Accordion className="code">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "17px",
                    padding: "0.4rem",
                  }}
                >
                  Team Augmentation
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    background: "#fff",
                    position: "relative",
                    zIndex: 999,
                    border: "1px solid rgba(0, 0, 0, .5)",
                  }}
                  className="z"
                >
                  <ul class="list-group list-group-vertical ">
                    <li class="list-group-item list">Python</li>
                    <li class="list-group-item list">Java</li>
                    <li class="list-group-item list">React</li>
                    <li class="list-group-item list">PHP</li>
                    <li class="list-group-item list">Power BI</li>
                    <li class="list-group-item list">Tableu</li>
                  </ul>
                  <ul class="list-group list-group-vertical">
                    <li class="list-group-item list">Node</li>
                    <li class="list-group-item list">React Native</li>
                    <li class="list-group-item list">Flutter</li>
                    <li class="list-group-item list">UI/UX</li>
                    <li class="list-group-item list">.net</li>
                    <li class="list-group-item list">Low Code</li>
                  </ul>
                  <ul class="list-group list-group-vertical">
                    <li class="list-group-item list">Data Engineers</li>
                    <li class="list-group-item list">Data Scientist</li>
                    <li class="list-group-item list">Dynamics 365</li>
                    <li class="list-group-item list">Blockchain</li>
                    <li class="list-group-item list">DevOps</li>
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Application Development</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Cloud Computing</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Devops Consulting</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Big Data and Analytics</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Artificial Intelligence</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mb-4">
            <div class="card car">
              <div class="card-body">
                <h5 class="card-title">Quality Engineering</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollButton />
    </div>
  );
};

export default About;
