import React, { useEffect, useState } from 'react'
import './Portfolio.css'
import ScrollButton from '../../Components/ScrollToTop/ScrollButton'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Link } from 'react-router-dom'

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className='careers-section'>
        <div className='careers'>
          <AnimationOnScroll animateIn='animate__fadeInLeft'>
            <div className='title'>
              <p>Portfolio</p>
            </div>
          </AnimationOnScroll>
        </div>

        <div className='container'>
          <div className='careers-services'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <Link to='amazon-d3play'>
                  <div className='portfolio-card'>
                    <div>
                      <img
                        src='https://www.ziancetechnologies.com/wp-content/uploads/2024/03/D3PLoy-1-605x605.png'
                        alt='amazon d3play'
                      />
                    </div>
                    <div className='services-desc serv'>
                      <p className='services-title'>Amazon D3Play</p>
                      <p>
                        We had work on Amazon platfrom to work on Amazon D3Ploy.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <Link to='benchmark-estimating-software'>
                  <div className='portfolio-card'>
                    <div>
                      <img
                        src='https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Benchmark-software-605x605.jpeg'
                        alt='Benchmark Estimating Software'
                      />
                    </div>
                    <div className='services-desc serv'>
                      <p className='services-title'>
                        Benchmark Estimating Software
                      </p>
                      <p>
                        Benchmark Estimating Software provides solutions for
                        accurate and efficient estimating in various industries.
                        Offering advanced tools and features.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <Link to='pure-gambler'>
                  <div className='portfolio-card'>
                    <div>
                      <img
                        src='https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Untitled-design-1.png'
                        alt='pure gambler'
                      />
                    </div>
                    <div className='services-desc serv'>
                      <p className='services-title'>PureGambler</p>
                      <p>
                        Gambling plateform with casino games and sport betting
                        features.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <Link to='ticket-souq'>
                  <div className='portfolio-card'>
                    <div>
                      <img
                        src='https://www.ziancetechnologies.com/wp-content/uploads/2024/03/ticket_souq_logo-transformed.jpeg'
                        alt='Ticket Souq'
                      />
                    </div>
                    <div className='services-desc serv'>
                      <p className='services-title'>Ticket Souq</p>
                      <p>
                        Ticket Souq redefines the ticketing experience by
                        offering a centralized online platform for effortlessly
                        browsing, purchasing, and managing tickets across
                        diverse events, from concerts and sports to theaters and
                        attractions.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <Link to='pushpak-ai'>
                  <div className='portfolio-card'>
                    <div>
                      <img
                        src='https://www.ziancetechnologies.com/wp-content/uploads/2024/03/pushpak_ai_logo-transformed.jpeg'
                        alt='Pushpak AI'
                      />
                    </div>
                    <div className='services-desc serv'>
                      <p className='services-title'>Pushpak AI</p>
                      <p>
                        Pushpak AI offers innovative services ranging from
                        Vehicle Entry-Exit systems and Number Plate Detection to
                        COVID safety implementations, People Counting solutions,
                        and Facial Recognition applications.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <ScrollButton />
      </div>
    </div>
  )
}

export default Portfolio
