import { useParams } from "react-router-dom";
import "./PortfolioId.css";

const portfoliData = {
  "amazon-d3play": {
    title: "Amazon D3Ploy",
    src: "https://www.ziancetechnologies.com/wx`p-content/uploads/2024/03/Screenshot-2024-03-14-131922-394x512.png",
    paras: [
      `Novamic Technologies played a pivotal role in the comprehensive development and successful implementation of Amazon D3Ploy on the Amazon platform.
This platform serves as Amazon’s efficient and budget-friendly vulnerability report system, accommodating diverse financial considerations. Our dedicated team actively contributed to shaping and refining the various features of Amazon D3Ploy, including the creation of a Dashboard, Projects, Document Library, Composite View, Access Map, Module Tracker, Milestone Review, Financial Management, and Financial Management Reports.`,
      `Throughout the design, development, and deployment phases, our focus remained on optimizing performance, enhancing reliability, and elevating the user experience across Amazon D3Ploy’s functionalities.`,
      `By leveraging our technical expertise, we aimed to build a robust and efficient system that seamlessly aligns with the dynamic requirements set by Amazon for its deployment services. The result is a sophisticated and reliable platform that adheres to the high standards set by Amazon for deployment solutions.`,
    ],
    href: "https://uat-d3ploy.ges.amazon.dev/",
  },
  "benchmark-estimating-software": {
    title: "Benchmark Estimating Software",
    src: "https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Screenshot-2024-03-14-130743-1536x760.png",
    paras: [
      `Novamic Technologies helped create Benchmark Estimating Software to make estimating easier for different businesses. We added smart tools and features to the software, so businesses can be more accurate, save time, and manage project costs better.`,
      `The software has an easy-to-use design as we wanted to make sure professionals could navigate the software without any problems, making estimating simpler and more user-friendly.`,
      `One important technical feature we added is advanced tools to improve accuracy. The software helps with calculating project costs precisely and making sure resources are used efficiently. This gives professionals the tools they need to make smart decisions and get the best results.`,
      `Novamic Technologies also created features that help with planning and reporting. This adds a level of sophistication to the estimating process, making Benchmark Estimating Software a great choice for professionals who want a simple and effective tool for estimating project costs.`,
    ],
    href: "https://www.benchmarkestimating.com/",
  },
  "pure-gambler": {
    title: "PureGambler",
    src: "https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Screenshot-2024-03-15-192924-1536x758.png",
    paras: [
      `Novamic Technologies designed and implemented features that prioritize fair play, security, and user-friendly interactions, ensuring PureGambler stands as a premier destination in the online gambling industry.`,
      `Key features crafted by Novamic Technologies include a sophisticated casino games suite with a diverse array of thrilling gaming options. From classic favorites to innovative, the platform caters to varied preferences, enhancing the overall gaming experience.`,
      `The platform’s intuitive interface ensures seamless navigation, empowering users to explore and participate in sports betting with ease.`,
      `Security is paramount in the world of online gambling, and Novamic Technologies addressed this by implementing advanced security protocols and encryptions to safeguard user data and financial transactions.`,
      `Additionally, Novamic Technologies prioritized the development of a responsive and user-friendly interface to facilitate easy navigation across the platform, from account management to exploring gaming options and placing bets.`,
    ],
    href: "https://puregambler.com/",
  },
  "ticket-souq": {
    title: "Ticket Souq",
    src: "https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Screenshot-2024-03-14-123235-1536x763.png",
    paras: [
      `Novamic Technologies undertook a transformative journey to bring Ticket Souq to life, a comprehensive platform designed to revolutionize the ticketing process for diverse events and experiences. Rooted in the vision of simplifying the ticketing terrain, Ticket Souq emerged as a user-centric solution, providing a centralized online hub for a multitude of entertainment choices.`,
      `Our work at Novamic Technologies involved framing the backend infrastructure and frontend interfaces. Leveraging robust technologies and frameworks, we ensured a scalable and efficient system capable of handling the intricacies of event ticketing. The platform seamlessly integrates a variety of events, from concerts and sports gatherings to theaters and attractions.`,
      `The user experience was a focal point in our development efforts. Novamic Technologies designed intuitive interfaces on the frontend to facilitate easy browsing, purchasing, and management of tickets.`,
      `Moreover, the platform’s backend architecture was engineered to handle the complexities of event management, ticket distribution, and transaction processing. Novamic Technologies implemented secure payment gateways as well.`,
    ],
    href: "https://www.ticket-souq.com/",
  },
  "pushpak-ai": {
    title: "Pushpak AI",
    src: "https://www.ziancetechnologies.com/wp-content/uploads/2024/03/Screenshot-2024-03-14-121957-1536x760.png",
    paras: [
      `Pushpak AI stands at the convergence of innovation and practicality, delivering futurist services to its users. Our expertise lies in leveraging Python, complemented seamlessly by AngularJS.`,
      `Within our portfolio, we have engineered Vehicle Entry-Exit systems, where Python serves as the robust foundation of the application. Angular, our chosen front-end technology, enhances the user experience with its responsive and dynamic interface.`,
      `In the domain of surveillance and security, our Number Plate Detection feature showcases an efficient system adept at precisely identifying and processing number plates across diverse scenarios. Our implementation of COVID safety features facilitates the implementation and management of safety protocols during the ongoing pandemic.`,
      `The People Counting solutions feature provides real-time insights into crowd dynamics, contributing to effective analytics and decision-making.`,
      `Our Facial Recognition applications exemplify our dedication to an engaging and efficient user experience. Python, at the backend, powers complex algorithms for facial analysis and identification, while Angular ensures a seamless and user-friendly interaction for individuals engaging with facial recognition systems.`,
    ],
    href: "https://www.pushpak.ai/",
  },
};

const PortfolioId = () => {
  const { id } = useParams();
  return (
    <div className="container">
      <h1 className="portfolio-id-heading">{portfoliData[id].title}</h1>
      <hr />
      <img
        className="portfolio-id-img"
        src={portfoliData[id].src}
        alt={portfoliData[id].title}
      />
      <div className="portfolio-id-text">
        {portfoliData[id].paras.map((para, index) => (
          <p key={index}>{para}</p>
        ))}
      </div>

      <a className="portfolio-id-link" href={portfoliData[id].href}>
        Visit Website
      </a>
    </div>
  );
};
export default PortfolioId;
